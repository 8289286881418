import React, { Suspense } from 'react';

import './View3d.scss';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Preload,
  Center,
  Stage,
  AdaptiveEvents,
  AdaptiveDpr,
  useGLTF,
  FlyControls,
} from '@react-three/drei';
import { useLocation } from 'react-router-dom';

import Loader from './Loader';
import Model from './Model';
import FullScreenCanvas from './FullScreenCanvas';
import Controls from './Controls';
import ViewTools from '../../atoms/ViewTools/ViewTools';
import AnnotationList from '../../atoms/AnnotationList/AnnotationList';
import AnnotationDetail from '../../atoms/AnnotationDetail.js/AnnotationDetail';

function View3d({ annotationHandler, showAnnotation }) {
  const location = useLocation();
  useGLTF.preload(location.state);

  return (
    <div className="view-container">
      <ViewTools annotationHandler={annotationHandler} />
      {/* Write Canvas Code here */}
      <FullScreenCanvas>
        {/* <TransformControls mode="translate" /> */}
        <Controls />
        <FlyControls autoForward={false} dragToLook movementSpeed={10} />
        {/* <fog attach="fog" args={['#041830', 5, 10]} /> */}
        <Suspense fallback={<Loader />}>
          {/* <TransformControls> */}

          {/* <Bounds fit clip observe damping={6} margin={1.2}> */}
          <Stage
            makeDefault
            contactShadow
            shadows
            adjustCamera
            intensity={1}
            environment="city"
            preset="rembrandt"
          >
            {/* <OrbitControls makeDefault /> */}
            <Center left right top bottom>
              <group dispose={null}>
                <Model modelUrl={location.state} />
                <AdaptiveDpr pixelated />
                <AdaptiveEvents />
                <Preload all />
              </group>
            </Center>
          </Stage>
          {/* </Bounds> */}

          {/* </TransformControls> */}
        </Suspense>
        {/* <Sphere /> */}
        {/* <Plane /> */}
        <gridHelper args={[100, 100]} />
      </FullScreenCanvas>

      <div />
      {showAnnotation ? (
        <div className="annotation-details">
          <Container className="h-100">
            <Row className="h-100">
              <Col lg="6" xl="4" className="left-annotation h-100">
                <AnnotationList annotationHandler={annotationHandler} />
              </Col>
              <Col lg="6" xl="8" className="h-100">
                <AnnotationDetail annotationHandler={annotationHandler} />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default View3d;
