import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectPreview.scss';
import star from '../../../assets/images/star.svg';
import placeholder from '../../../assets/images/profile-placeholder.svg';

function ProjectPreview({ id, title, time, previewImg, modelUrl }) {
  return (
    <div className="project-preview">
      <div className="project-preview-title">
        <h6 className="mb-1">{title}</h6>
        <p>{time}</p>
      </div>
      {modelUrl && (
        <Link to={`/dashboard/project/${id}`} state={modelUrl}>
          <div className="project-preview-img">
            <img src={previewImg} alt="project-preview" loading="lazy" />
          </div>
        </Link>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <img src={star} alt="like" />
        </div>
        <div>
          <img src={placeholder} alt="profile-placeholder" />
        </div>
      </div>
    </div>
  );
}

export default ProjectPreview;
