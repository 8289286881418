import React from 'react';
import './CommonBtn.scss';
import { Button } from 'react-bootstrap';

function CommonBtn({ text, isDisabled, handleSubmit }) {
  return (
    <Button
      className="common-btn mt-5 mb-3"
      disabled={isDisabled}
      onClick={handleSubmit}
    >
      {text}
    </Button>
  );
}

export default CommonBtn;
