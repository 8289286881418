import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './StepTwo.scss';
import file from '../../../../assets/images/file.svg';
import cross from '../../../../assets/images/file-clear.svg';

function StepTwo({
  glbFileData,
  setGlbFileData,
  imageFileData,
  setImageFileData,
  setCreate,
}) {
  useEffect(() => {
    if (glbFileData.file?.name && imageFileData.file?.name) {
      setCreate(true);
    }
  }, [glbFileData, imageFileData]);

  return (
    <div className="step-two">
      <div className="step-title mb-3">
        <p>Upload project data</p>
      </div>
      <div className="step-content">
        <p>Please upload files which includes:</p>
        <ul>
          <li>GLB File</li>
          <li>Thumbnail Image</li>
        </ul>
        {!glbFileData.file?.name ? (
          <label htmlFor="file-input" className="upload-file-btn">
            Select GLB file
            <input
              id="file-input"
              type="file"
              accept=".glb,.gltf"
              className="d-none"
              onChange={(e) =>
                setGlbFileData({
                  file: e.target.files[0],
                })
              }
            />
          </label>
        ) : (
          <div className="uploaded-file d-flex align-items-start justify-content-center">
            <div className="file-content d-flex ">
              <img src={file} alt="file" className="me-2 me-sm-4" />
              <div className="file-name">
                <h6>{glbFileData.file.name}</h6>
                <p>
                  {Math.floor(glbFileData.file.size / 1048576).toFixed(2)} MB
                </p>
              </div>
            </div>
            <div className="clear-file">
              <Button
                variant="transparent"
                className="clear-file-btn"
                onClick={() =>
                  setGlbFileData({
                    file: null,
                  })
                }
              >
                <img src={cross} alt="close" />
              </Button>
            </div>
          </div>
        )}
        {!imageFileData.file?.name ? (
          <label htmlFor="file-input1" className="upload-file-btn">
            Select Thumbnail Image
            <input
              id="file-input1"
              type="file"
              accept="image/*"
              className="d-none"
              onChange={(e) =>
                setImageFileData({
                  file: e.target.files[0],
                })
              }
            />
          </label>
        ) : (
          <div className="uploaded-file d-flex align-items-start justify-content-center">
            <div className="file-content d-flex ">
              <img src={file} alt="file" className="me-2 me-sm-4" />
              <div className="file-name">
                <h6>{imageFileData.file.name}</h6>
                <p>{(imageFileData.file.size / 1048576).toFixed(2)} MB</p>
              </div>
            </div>
            <div className="clear-file">
              <Button
                variant="transparent"
                className="clear-file-btn"
                onClick={() =>
                  setImageFileData({
                    file: null,
                  })
                }
              >
                <img src={cross} alt="close" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepTwo;
