import React from 'react';
import './Welcome.scss';
import Slider from 'react-slick';
import banner from '../../../assets/images/building.svg';

function Welcome({ heading, content }) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dotsClass: 'slick-dots slick-custom-dots',
  };
  return (
    <div className="welcome-container d-flex flex-column justify-content-between align-items-center">
      <div className="welcome-content text-center w-100">
        <h5 className="text-primary">{heading}</h5>
        <p>{content}</p>
      </div>
      <div className="welcome-banner w-100">
        <Slider {...settings}>
          <div className="banner-img">
            <img src={banner} alt="banner" />
          </div>
          <div className="banner-img">
            <img src={banner} alt="banner" />
          </div>
          <div className="banner-img">
            <img src={banner} alt="banner" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Welcome;
