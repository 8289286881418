import React from 'react';
import './SearchInp.scss';
import search from '../../../../assets/images/search-icon.svg';

function SearchInp() {
  return (
    <div className="search-inp d-flex align-items-center">
      <img src={search} alt="search" className="me-2" />
      <input type="text" placeholder="Search..." />
    </div>
  );
}

export default SearchInp;
