import React from 'react';
import './StepperHeader.scss';
import rightArrow from '../../../../assets/images/next-arrow.svg';

function StepperHeader({ step }) {
  return (
    <div className="stepper-header d-flex align-items-start justify-content-between">
      <div className="steps text-center">
        <span className={`${step === 1 ? 'active' : ''}`}>1</span>
        <p>Details</p>
      </div>
      <img src={rightArrow} alt="next-arrow" />
      <div className="steps text-center">
        <span className={`${step === 2 ? 'active' : ''}`}>2</span>
        <p>Files</p>
      </div>
      <img src={rightArrow} alt="next-arrow" />
      <div className="steps text-center">
        <span className={`${step === 3 ? 'active' : ''}`}>3</span>
        <p>Upload</p>
      </div>
      <img src={rightArrow} alt="next-arrow" />
      <div className="steps text-center">
        <span className={`${step === 4 ? 'active' : ''}`}>4</span>
        <p>Completed</p>
      </div>
    </div>
  );
}

export default StepperHeader;
