import React from 'react';
import './AuthLogin.scss';
import Welcome from '../../../organisms/Welcome/Welcome';
import LogIn from '../../../organisms/LogIn/LogIn';

function AuthLogin() {
  return (
    <div className="auth-login">
      <div className="auth-container d-flex h-100 w-100">
        <div className="welcome-left d-none d-lg-block">
          <Welcome
            heading="Welcome Back"
            content="Just a couple of clicks to get started"
          />
        </div>
        <div className="auth-right">
          <LogIn />
        </div>
      </div>
    </div>
  );
}

export default AuthLogin;
