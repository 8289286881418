import React, { useState } from 'react';
import './CheckMark.scss';
import checked from '../../../assets/images/checked.svg';
import uncheck from '../../../assets/images/uncheck.svg';

function CheckMark({ text }) {
  const [check, setCheck] = useState(false);
  return (
    <button
      type="button"
      onClick={() => setCheck(!check)}
      className="check-mark d-flex align-items-center mb-4"
    >
      <img src={check ? checked : uncheck} alt="tick" className="me-2" />
      <span>{text}</span>
    </button>
  );
}

export default CheckMark;
