import React, { useEffect, useState } from 'react';
import './StepThree.scss';
import { Button } from 'react-bootstrap';
import file from '../../../../assets/images/file-uploading.svg';
import cross from '../../../../assets/images/file-clear.svg';
import {
  getPresignedUrl,
  uploadFiles,
  postProject,
} from '../../../../api/ProjectApi';

function StepThree({
  createProjectField,
  glbFileData,
  imageFileData,
  setCreate,
}) {
  const [response, setResponse] = useState([]);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [count, setCount] = useState(0);

  // helper function to upload the files
  function uploadFile(processFile, awsResponse) {
    const formData = new FormData();

    Object.keys(awsResponse.fields).forEach((key) => {
      formData.append(key, awsResponse.fields[key]);
    });

    formData.append('file', processFile.file);
    if (count < 2) {
      uploadFiles(formData, setUploadingProgress)
        .then(() => {
          setCount(count + 1);
        })
        .catch((e) => console.log(e.message));
    }
  }

  useEffect(() => {
    getPresignedUrl(
      createProjectField.projectName,
      glbFileData.file?.name,
      imageFileData.file?.name
    )
      .then((e) => {
        const data = Object.values(e);
        setResponse([...data]);
      })
      .catch((e) => console.error(`Error ${e.message}`));
  }, []);

  useEffect(() => {
    if (response.length) {
      uploadFile(glbFileData, response[0]);
    }
  }, [response]);

  useEffect(() => {
    if (count === 1) {
      uploadFile(imageFileData, response[1]);
    }
    if (count === 2) {
      const formData = new FormData();
      formData.append('project_name', createProjectField.projectName);
      formData.append('asset_type', createProjectField.assetType);
      formData.append(
        'reconstruction_software',
        createProjectField.softwareType
      );
      formData.append(
        'project_file_url',
        `https://deltascan-bucket.s3.ap-south-1.amazonaws.com/${response[0].fields.key.split(' ')
        .join('+')}`
      );
      formData.append(
        'image_url',
        `https://deltascan-bucket.s3.ap-south-1.amazonaws.com/${response[1].fields.key
          .split(' ')
          .join('+')}`
      );
      postProject(formData)
        .then(setCreate(true))
        .catch((e) => console.error(e.message));
      setCreate(true);
    }
  }, [count]);

  return (
    <div className="step-three">
      <div className="step-title mb-3">
        <p>Upload project data</p>
      </div>
      {count === 0 ? (
        <div className="loading-file">
          <div className="w-100 d-flex align-items-start justify-content-between mb-3">
            <div className="file-content d-flex">
              <img src={file} alt="file" className="me-2 me-sm-4" />
              <div className="file-name">
                <h6>{glbFileData.file.name}</h6>
                <p>
                  {Math.floor(glbFileData.file.size / 1048576).toFixed(2)} MB
                </p>
              </div>
            </div>
            <div className="clear-file">
              <Button variant="transparent" className="clear-file-btn">
                <img src={cross} alt="close" />
              </Button>
            </div>
          </div>
          <div className="file-progress-bar">
            <div
              className="file-current-progress"
              style={{ width: `${uploadingProgress}%` }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-1">
            {/* <div className="file-progree-content">
            <p>Approx. in 1 minute (223.11kb/s)</p>
          </div> */}
            <div className="file-progree-content">
              <p>{uploadingProgress}%</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-file">
          <div className="w-100 d-flex align-items-start justify-content-between mb-3">
            <div className="file-content d-flex">
              <img src={file} alt="file" className="me-2 me-sm-4" />
              <div className="file-name">
                <h6>{imageFileData.file.name}</h6>
                <p>{(imageFileData.file.size / 1048576).toFixed(3)} MB</p>
              </div>
            </div>
            <div className="clear-file">
              <Button variant="transparent" className="clear-file-btn">
                <img src={cross} alt="close" />
              </Button>
            </div>
          </div>
          <div className="file-progress-bar">
            <div
              className="file-current-progress"
              style={{ width: `${uploadingProgress}%` }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-1">
            {/* <div className="file-progree-content">
            <p>Approx. in 1 minute (223.11kb/s)</p>
          </div> */}
            <div className="file-progree-content">
              <p>{uploadingProgress}%</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StepThree;
