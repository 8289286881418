import axios, { post } from 'axios';

const client = axios.create({
  baseURL: 'https://datascan-backend.invo.zone/api',
  headers: {
    Authorization:
      'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYyNTc1OTU0LCJpYXQiOjE2NjE5NzExNTQsImp0aSI6IjkwYWMwZjRjM2ZmMDQxZWY4NTM4M2FiYTI5NGMwN2FmIiwidXNlcl9pZCI6MX0.QiQ_VdonKYRhcp94sUUKo1jqEh-kTrvkUONlp1Rk5L4',
  },
});

export const login = async (formdata) => {
  const response = await client.post('/account/login/', formdata);
  return response.data;
};

export const getPresignedUrl = async (
  projectName,
  glbFileName,
  imageFileName
) => {
  const response = await client.get(
    `/project/get_url/${projectName}?file_name=${glbFileName}&image_name=${imageFileName}`
  );
  return response.data;
};

export const postProject = async (formdata) => {
  const response = await client.post('/project', formdata);
  return response.data;
};

export const listProject = async () => {
  const response = await client.get('/project');
  return response.data;
};

export const retrieveProject = async (id) => {
  const response = await client.get(`/project/${id}`);
  return response.data;
};

// aws pre-signed url
export async function uploadFiles(formData, setUploadProgress) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      setUploadProgress(percent);
    },
  };
  const response = await post(
    'https://deltascan-bucket.s3.amazonaws.com/',
    formData,
    config
  );
  return response.data;
}
