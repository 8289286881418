import React, { useEffect, useState } from 'react';
import './CreateProject.scss';
import { Modal, Button } from 'react-bootstrap';
import cross from '../../../assets/images/modal-cross.svg';
import StepperHeader from '../Stepper/StepperHeader/StepperHeader';
import StepOne from '../Stepper/StepOne/StepOne';
import StepTwo from '../Stepper/StepTwo/StepTwo';
import StepThree from '../Stepper/StepThree.js/StepThree';
import StepFour from '../Stepper/StepFour/StepFour';

function CreateProject({ show, modalHandler }) {
  const [step, setStep] = useState(1);
  const [create, setCreate] = useState(false);
  const [createProjectField, setCreateProjectField] = useState({
    projectName: '',
    assetType: '',
    softwareType: '',
  });
  const [glbFileData, setGlbFileData] = useState({
    file: null,
  });
  const [imageFileData, setImageFileData] = useState({
    file: null,
  });

  useEffect(() => {
    if (step >4) {
      setCreateProjectField({
        projectName: '',
        assetType: '',
        softwareType: '',
      });
      setGlbFileData({ file: null });
      setImageFileData({ file: null });
      setStep(1);
    }
  }, [step]);

  // eslint-disable-next-line consistent-return
  const showComponent = (id) => {
    if (id === 1) {
      return (
        <StepOne
          createProjectField={createProjectField}
          setCreateProjectField={setCreateProjectField}
          setCreate={setCreate}
        />
      );
    }
    if (id === 2) {
      return (
        <StepTwo
          glbFileData={glbFileData}
          setGlbFileData={setGlbFileData}
          imageFileData={imageFileData}
          setImageFileData={setImageFileData}
          setCreate={setCreate}
        />
      );
    }
    if (id === 3) {
      return (
        <StepThree
          createProjectField={createProjectField}
          glbFileData={glbFileData}
          imageFileData={imageFileData}
          setCreate={setCreate}
        />
      );
    }
    if (id === 4) {
      return (
        <StepFour
          createProjectField={createProjectField}
          setCreate={setCreate}
        />
      );
    }
    if (id > 4) {
      return modalHandler();
    }
  };

  return (
    <Modal
      show={show}
      onHide={modalHandler}
      size="lg"
      centered
      contentClassName="create-modal"
    >
      <Modal.Header>
        <div className="create-modal-header d-flex align-items-center justify-content-between w-100">
          <p>Create new project</p>
          <Button
            className="create-modal-close bg-transparent"
            onClick={modalHandler}
          >
            <img src={cross} alt="close" />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="create-modal-body">
          <StepperHeader step={step} />
          {showComponent(step)}
          <div className="create-modal-footer d-flex align-items-center justify-content-end">
            <Button
              className="create-modal-btn"
              disabled={!create}
              // eslint-disable-next-line consistent-return
              onClick={() => {
                setStep(step + 1);
                setCreate(false);
              }}
            >
              {step === 4 ? 'Contiune' : 'Next'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateProject;
