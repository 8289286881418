import React from 'react';
import './AuthHeader.scss';
import logo from '../../../assets/images/logo.svg';

function AuthHeader({ heading }) {
  return (
    <div>
      <div className="auth-header mb-4">
        <img src={logo} alt="data-scan" />
      </div>
      <div className="text-center mb-4">
        <h6>{heading}</h6>
      </div>
    </div>
  );
}

export default AuthHeader;
