import React from 'react';
import './EmailSent.scss';
import { Link } from 'react-router-dom';
import CommonBtn from '../../atoms/Buttons/CommonBtn/CommonBtn';

import AuthHeader from '../../atoms/AuthHeader/AuthHeader';

function EmailSent() {
  return (
    <div className="email-container">
      <div className="login-content">
        <AuthHeader heading="Email Sent!" />
        <div className="sent-text text-center">
          <p className="px-3 mb-3">
            We’ve sent a message on your email so you can pick your new password
          </p>
          <span>Not your email address?</span>
        </div>
        <CommonBtn text="Try again" />
        <div className="create-acc text-center mt-5">
          <span>
            Have an account? <Link to="/"> Login</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default EmailSent;
