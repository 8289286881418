import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import SecurityLevel from './SecurityLevel/SecurityLevel';
import './AnnotationDetail.scss';
import measure from '../../../assets/images/measure.svg';
import close from '../../../assets/images/close-icon.svg';

function AnnotationDetail({ annotationHandler }) {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  return (
    <div className="annotation-detail">
      <div className="annotation-detail-header d-flex align-items-start justify-content-between">
        <div className="header-title d-flex align-items-center">
          <img src={measure} alt="measure" className="me-2 me-lg-3" />
          <p>1 - Concret damange - Distance: 4.34 m</p>
        </div>
        <div className="d-none d-lg-block">
          <Button
            variant="transparent"
            className="p-0"
            onClick={annotationHandler}
          >
            <img src={close} alt="close" />
          </Button>
        </div>
      </div>

      <div className="annotation-detail-content">
        <Container>
          <Row className="g-3">
            <Col lg="3" className="d-flex align-items-center">
              <div className="detail-title">
                <p>Title</p>
              </div>
            </Col>
            <Col lg="9">
              <div className="detail-select">
                <Select
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  options={options}
                  classNamePrefix="detail-select"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Title"
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="detail-title">
                <p>Description</p>
              </div>
            </Col>
            <Col lg="9">
              <div className="detail-description">
                <textarea rows="4" />
              </div>
            </Col>
            <Col lg="3" className="d-flex align-items-center">
              <div className="detail-title">
                <p>External Link</p>
              </div>
            </Col>
            <Col lg="9">
              <div className="detail-link">
                <input />
              </div>
            </Col>
            <Col lg="3" className="d-flex align-items-center">
              <div className="detail-title">
                <p>Severity Level</p>
              </div>
            </Col>
            <Col lg="9">
              <div className="detail-security">
                <SecurityLevel />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AnnotationDetail;
