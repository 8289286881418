import React from 'react';
import './ProjectHeader.scss';
import { Button } from 'react-bootstrap';
import ProjectSelect from '../../atoms/ProjectSelect/ProjectSelect';
import SearchInp from '../../atoms/Inputs/SearchInp/SearchInp';

function ProjectHeader({ modalHandler }) {
  return (
    <div className="project-header py-3 py-sm-0">
      <div className="mb-3 mb-sm-0">
        <h6>Projects</h6>
      </div>
      <div className="project-header-options d-flex flex-column flex-sm-row align-items-center mx-auto me-sm-0 ms-sm-auto">
        <div className="project-header-search mb-2 mb-sm-0 me-sm-2 me-lg-5">
          <SearchInp />
        </div>
        <div className="project-header-select mb-2 mb-sm-0 me-sm-2 me-lg-4">
          <ProjectSelect />
        </div>

        <Button
          className="project-header-btn mb-2 mb-sm-0"
          onClick={modalHandler}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default ProjectHeader;
