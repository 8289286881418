import React from 'react';
import './AuthSignup.scss';
import Welcome from '../../../organisms/Welcome/Welcome';
import CreateAcc from '../../../organisms/CreateAcc/CreateAcc';

function AuthSignup() {
  return (
    <div className="auth-signup-container d-flex  w-100">
      <div className="welcome-left d-none d-lg-block">
        <Welcome
          heading="Welcome Back"
          content="Just a couple of clicks to get started"
        />
      </div>
      <div className="auth-right">
        <CreateAcc />
      </div>
    </div>
  );
}

export default AuthSignup;
