import React from 'react';
import './ProjectSelect.scss';
import Select from 'react-select';

function ProjectSelect() {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  return (
    <div className="project-select">
      <Select
        options={options}
        classNamePrefix="project-select"
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder="Name"
      />
    </div>
  );
}

export default ProjectSelect;
