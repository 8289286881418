import React, { useState } from 'react';
import './SecurityLevel.scss';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

function SecurityLevel() {
  const [radioValue, setRadioValue] = useState('');
  const radios = [
    { name: '1', value: '1', variant: 'green' },
    { name: '2', value: '2', variant: 'red' },
    { name: '3', value: '3', variant: 'blue' },
    { name: '4', value: '4', variant: 'success' },
  ];
  return (
    <ButtonGroup className="btn-security-group">
      {radios.map((radio, idx) => (
        <ToggleButton
          key={radio.value}
          id={`radio-${idx}`}
          type="radio"
          variant="transparent"
          className={`btn-security btn-${radio.variant}-security ${
            radioValue === radio.value ? 'checked' : ''
          }`}
          value={radio.value}
          checked={radioValue === radio.value}
          onChange={(e) => setRadioValue(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}

export default SecurityLevel;
