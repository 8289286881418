import React from 'react';
import './AuthVerification.scss';
import Welcome from '../../../organisms/Welcome/Welcome';
import EmailSent from '../../../organisms/EmailSent/EmailSent';

function AuthVerification() {
  return (
    <div className="auth-verify">
      <div className="auth-container d-flex h-100 w-100">
        <div className="welcome-left d-none d-lg-block">
          <Welcome
            heading="Welcome Back"
            content="Just a couple of clicks to get started"
          />
        </div>
        <div className="auth-right">
          <EmailSent />
        </div>
      </div>
    </div>
  );
}

export default AuthVerification;
