import React from 'react';
import './CommonInp.scss';

function CommonInp({ inpLabel, inpType, inpPlaceholder, handleChange }) {
  return (
    <div className="common-inp mb-3">
      <p>{inpLabel}</p>
      <input
        type={inpType}
        name="email"
        placeholder={inpPlaceholder}
        onChange={handleChange}
      />
    </div>
  );
}

export default CommonInp;
