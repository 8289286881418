import React from 'react';
import './SingleList.scss';
import { Button } from 'react-bootstrap';
import copy from '../../../../assets/images/copy-icon.svg';
import deleteIcon from '../../../../assets/images/del-icon.svg';

function SingleList({ title, active, type, id }) {
  return (
    <div
      className={`annotation-list ${
        active ? 'active-list' : ''
      } d-flex align-items-center justify-content-between`}
    >
      <div className="d-flex align-items-center list-title">
        <input type="checkBox" className="me-2" />
        <img src={type} alt="measure" className="me-2" />
        <span className="me-1">{id}-</span>
        <span>{title}</span>
      </div>
      <div className="d-flex align-items-center">
        <Button variant="transparent" className="p-0 me-2">
          <img src={copy} alt="copy" />
        </Button>
        <Button variant="transparent" className="p-0 me-1">
          <img src={deleteIcon} alt="delete" />
        </Button>
      </div>
    </div>
  );
}

export default SingleList;
