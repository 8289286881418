import React from 'react';
import { Button } from 'react-bootstrap';
import './AddComment.scss';
import comment from '../../../assets/images/addcomment.svg';
import measure from '../../../assets/images/measure.svg';
import annotation from '../../../assets/images/annotation.svg';

function AddComment() {
  return (
    <div className="addcomment">
      <div className="addcomment-title d-flex align-items-center">
        <img src={annotation} alt="annotation" className="me-2" />
        <p>Annotation & Measure</p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Button variant="light" className="btn-addcomment">
          <img src={comment} alt="add-comment" />
        </Button>
        <Button variant="light" className="btn-addcomment">
          <img src={measure} alt="add-comment" />
        </Button>
      </div>
    </div>
  );
}

export default AddComment;
