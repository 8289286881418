import { extend, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

function Controls() {
  const { camera, gl } = useThree();
  useEffect(() => {
    const controls = new OrbitControls(camera, gl.domElement);
    // camera.getWorldPosition.seLength(100);
    controls.enableRotate = true;
    controls.enablePan = true;
    return () => {
      controls.dispose();
    };
  }, [camera, gl]);
  return null;
}

export default Controls;
