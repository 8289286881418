import React from 'react';
import './FindAcc.scss';
import { Link } from 'react-router-dom';
import CommonBtn from '../../atoms/Buttons/CommonBtn/CommonBtn';
import CommonInp from '../../atoms/Inputs/CommonInp/CommonInp';
import AuthHeader from '../../atoms/AuthHeader/AuthHeader';

function FindAcc() {
  return (
    <div className="findacc-container">
      <div className="login-content">
        <AuthHeader heading="Find your account" />
        <CommonInp
          inpLabel="Email"
          inpPlaceholder="johndoe@gmail.com"
          inpType="email"
        />
        <CommonBtn text="Send verification code" />
        <div className="create-acc text-center mt-5">
          <span>
            Have an account? <Link to="/"> Login</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default FindAcc;
