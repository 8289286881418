import React from 'react';
import { Canvas } from '@react-three/fiber';

function FullScreenCanvas(props) {
  const { children } = props;

  const { innerWidth, innerHeight } = window;

  return (
    <Canvas
      style={{
        width: `${innerWidth}px`,
        height: `${innerHeight}px`,
      }}
      color="black"
      camera={{ position: [3, 3, 3] }}
    >
      {children}
    </Canvas>
  );
}

export default FullScreenCanvas;
