import React, { useState } from 'react';
import './PasswordInp.scss';
import eye from '../../../../assets/images/eye.svg';
import hide from '../../../../assets/images/hide.svg';

function PasswordInp({ inpLabel, handleChange }) {
  const [show, setShow] = useState(false);
  return (
    <div className="pass-inp-container mb-3">
      <p>{inpLabel}</p>
      <div className="pass-inp d-flex align-items-center">
        <input
          type={show ? 'text' : 'password'}
          name="password"
          placeholder="********"
          onChange={handleChange}
        />
        <button
          type="button"
          className="pass-eye"
          onClick={() => setShow(!show)}
        >
          <img src={show ? hide : eye} alt="eye" />
        </button>
      </div>
    </div>
  );
}

export default PasswordInp;
