import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignIn from './pages/SignIn/SignIn';
import FindAccount from './pages/FindAccount/FindAccount';
import SignUp from './pages/SignUp/SignUp';
import Verification from './pages/Verification/Verification';
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import AllProjects from './pages/AllProjects/AllProjects';
import ViewProject from './pages/ViewProject/ViewProject';

function App() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/find-acc" element={<FindAccount />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<Verification />} />
      <Route
        path="/dashboard/projects"
        element={
          <DashboardLayout>
            <AllProjects />
          </DashboardLayout>
        }
      />
      <Route
        path="/dashboard/project/:id"
        element={
          <DashboardLayout>
            <ViewProject />
          </DashboardLayout>
        }
      />
    </Routes>
  );
}

export default App;
