import React from 'react';
import { Button } from 'react-bootstrap';
import './ViewTools.scss';
import { ReactComponent as Zoomin } from '../../../assets/images/zoom-in.svg';
import { ReactComponent as Zoomout } from '../../../assets/images/zoom-out.svg';
import { ReactComponent as Comment } from '../../../assets/images/comment.svg';

function ViewTools({ annotationHandler }) {
  return (
    <div className="view-tools text-center">
      <div className="title">
        <p>Tools</p>
      </div>
      <Button variant="light" className="btn-tool">
        <Zoomin />
      </Button>
      <Button variant="light" className="btn-tool">
        <Zoomout />
      </Button>
      <Button variant="light" className="btn-tool" onClick={annotationHandler}>
        <Comment />
      </Button>
    </div>
  );
}

export default ViewTools;
