import React from 'react';
import './StepFour.scss';
import file from '../../../../assets/images/file-uploading.svg';

function StepFour({ createProjectField, setCreate }) {
  setCreate(true);
  return (
    <div className="step-four">
      <div className="file-uploaded d-flex align-items-center justify-content-center">
        <div className="file-uploaded-content text-center">
          <img src={file} alt="file" />
          <h6>Successfully Created</h6>
          <p>{createProjectField.projectName}</p>
        </div>
      </div>
    </div>
  );
}

export default StepFour;
