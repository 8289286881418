import React from 'react';
import './CreateAcc.scss';
import { Link } from 'react-router-dom';
import CommonBtn from '../../atoms/Buttons/CommonBtn/CommonBtn';
import CommonInp from '../../atoms/Inputs/CommonInp/CommonInp';
import PasswordInp from '../../atoms/Inputs/PasswordInp/PasswordInp';
import CheckMark from '../../atoms/CheckMark/CheckMark';
import AuthHeader from '../../atoms/AuthHeader/AuthHeader';

function CreateAcc() {
  return (
    <div className="create-acc-container">
      <div className="login-content">
        <AuthHeader heading="Create your account" />
        <CommonInp
          inpLabel="Email"
          inpPlaceholder="johndoe@gmail.com"
          inpType="email"
        />
        <PasswordInp inpLabel="New Password" />
        <PasswordInp inpLabel="Confirm New Password" />
        <CommonInp inpLabel="Company" inpPlaceholder="Company" inpType="text" />
        <CommonInp
          inpLabel="Job Title"
          inpPlaceholder="Job title"
          inpType="text"
        />
        <CommonInp
          inpLabel="Given Name"
          inpPlaceholder="Given Name"
          inpType="text"
        />
        <CommonInp inpLabel="Surname" inpPlaceholder="Surname" inpType="text" />

        <div className="terms-link mb-3">
          <Link to="/">Terms and conditions</Link>
        </div>
        <CheckMark text="I agree to the terms and conditions" />
        <CommonBtn text="Create account" />

        <div className="create-acc mb-5">
          <span>
            Have an account? <Link to="/"> Login</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default CreateAcc;
