import React, { useState } from 'react';
import './AnnotationList.scss';
import { Button } from 'react-bootstrap';
import SingleList from './SingleList/SingleList';
import { ReactComponent as DownArrow } from '../../../assets/images/down-arrow.svg';
import folder from '../../../assets/images/folder-file.svg';
import annotation from '../../../assets/images/annotation.svg';
import close from '../../../assets/images/close-icon.svg';
import measure from '../../../assets/images/measure.svg';

function AnnotationList({ annotationHandler }) {
  const [showList, setShowList] = useState(false);
  const listData = [
    {
      id: '1',
      title: 'Concrete damage',
      type: annotation,
      active: true,
    },
    {
      id: '2',
      title: 'Concrete damage',
      type: measure,
      active: false,
    },
    {
      id: '3',
      title: 'Concrete damage',
      type: annotation,
      active: false,
    },
    {
      id: '4',
      title: 'Concrete damage',
      type: measure,
      active: false,
    },
    {
      id: '3',
      title: 'Concrete damage',
      type: annotation,
      active: false,
    },
    {
      id: '4',
      title: 'Concrete damage',
      type: measure,
      active: false,
    },
    {
      id: '3',
      title: 'Concrete damage',
      type: annotation,
      active: false,
    },
    {
      id: '4',
      title: 'Concrete damage',
      type: measure,
      active: false,
    },
  ];
  return (
    <div className="annotation-list-container">
      <div className="annotation-title-wrapper d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-center annotation-title">
          <img src={annotation} alt="annotation" className="me-2 me-lg-3" />
          <p> Annotation & Measure</p>
        </div>
        <div className="d-lg-none">
          <Button
            variant="transparent"
            className="p-0"
            onClick={annotationHandler}
          >
            <img src={close} alt="close" />
          </Button>
        </div>
      </div>
      <div
        className="annotation-list-header"
        onClick={() => setShowList(!showList)}
        onKeyDown={() => setShowList(!showList)}
        role="button"
        tabIndex="0"
      >
        <div
          className={`${
            showList ? 'down-arrow-active' : 'down-arrow'
          } me-2 me-lg-3`}
        >
          <DownArrow />
        </div>
        <input type="checkBox" className="me-2 me-lg-3" />
        <img src={folder} alt="folder" className="me-2" />
        <span>Annotations</span>
      </div>

      {showList ? (
        <div className="annotation-lists-wrapper">
          <div className="listing">
            {listData.map((data) => (
              <SingleList
                title={data.title}
                id={data.id}
                type={data.type}
                active={data.active}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default AnnotationList;
