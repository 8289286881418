import React, { useState } from 'react';

import './LogIn.scss';
import { Link } from 'react-router-dom';
import validator from 'validator';

import CommonBtn from '../../atoms/Buttons/CommonBtn/CommonBtn';
import CommonInp from '../../atoms/Inputs/CommonInp/CommonInp';
import PasswordInp from '../../atoms/Inputs/PasswordInp/PasswordInp';
import CheckMark from '../../atoms/CheckMark/CheckMark';
import AuthHeader from '../../atoms/AuthHeader/AuthHeader';
import { login } from '../../../api/ProjectApi';

function LogIn() {
  const [loginFields, setLoginFields] = useState({
    email: null,
    password: null,
  });

  // eslint-disable-next-line consistent-return
  const handleChange = (e) => {
    if (e.target.name === 'email' && !validator.isEmail(e.target.value))
      return setLoginFields((prevState) => ({
        ...prevState,
        email: null,
      }));
    setLoginFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', loginFields.email);
    formData.append('password', loginFields.password);
    login(formData)
      .then((data) => localStorage.setItem('token', JSON.stringify(data)))
      .catch((error) => alert(error.message));
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <AuthHeader heading="Sign in to your account" />
        <CommonInp
          inpLabel="Email"
          inpPlaceholder="johndoe@gmail.com"
          inpType="email"
          handleChange={handleChange}
        />
        <PasswordInp inpLabel="Password" handleChange={handleChange} />
        <div className="forget-pass mb-3 text-end">
          <Link to="/find-acc">Forgot password ?</Link>
        </div>
        <CommonBtn
          text="Sign In"
          isDisabled={!(loginFields.email && loginFields.password)}
          handleSubmit={handleSubmit}
        />
        <CheckMark text="Keep me signed in" />
        <div className="create-acc text-center">
          <span>
            {`Don't have an account?`}{' '}
            <Link to="/signup"> Creat an Account</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
