import React from 'react';
import './ViewHeader.scss';
import AddComment from '../../atoms/AddComment/AddComment';

function ViewHeader({ showAnnotation }) {
  return (
    <div className="view-header py-3 py-sm-0">
      <div className="view-header-title d-flex align-items-center mb-3 mb-sm-0">
        <h6 className="me-1 me-sm-2">Projects</h6>
        <span className="me-1 me-sm-2">/</span>
        <span>Stadium View.ds</span>
      </div>
      {showAnnotation ? (
        <div className="ms-auto mx-auto mx-sm-0 ms-sm-auto">
          <AddComment />
        </div>
      ) : (
        ' '
      )}
    </div>
  );
}

export default ViewHeader;
