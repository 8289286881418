import React, { useEffect } from 'react';
import './StepOne.scss';
import Select from 'react-select';

const options = [
  // Reality capture, Metashape, Context Capture
  { value: 'Tower', label: 'Tower' },
  { value: 'Platform', label: 'Platform' },
  { value: 'Bridge', label: 'Bridge' },
  { value: 'House', label: 'House' },
  { value: 'Roof', label: 'Roof' },
  { value: 'Other', label: 'Other' },
];

const options2 = [
  { value: 'Reality Capture', label: 'Reality Capture' },
  { value: 'Metashape', label: 'Metashape' },
  { value: 'Context Capture', label: 'Context Capture' },
];

function StepOne({ createProjectField, setCreateProjectField, setCreate }) {
  useEffect(() => {
    const list = Object.keys(createProjectField).filter(
      (e) => createProjectField[e]
    );
    if (list.length !== Object.keys(createProjectField).length) {
      setCreate(false);
    } else {
      setCreate(true);
    }
  }, [createProjectField]);

  return (
    <div className="step-one">
      <div className="step-title mb-3">
        <p>Enter the required details</p>
      </div>
      <div className="step-input mb-3">
        <p className="mb-1">
          Project Name <span>*</span>
        </p>
        <input
          type="text"
          placeholder="Enter a name for new project"
          onChange={(e) =>
            setCreateProjectField((prevState) => ({
              ...prevState,
              projectName: e.target.value,
            }))
          }
        />
      </div>
      <div className="step-input mb-3">
        <p className="mb-1">
          Asset type <span>*</span>
        </p>
        <div className="step-select">
          <Select
            options={options}
            classNamePrefix="step-select"
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Select asset type"
            onChange={(e) =>
              setCreateProjectField((prevState) => ({
                ...prevState,
                assetType: e.value,
              }))
            }
          />
        </div>
      </div>
      <div className="step-input mb-3">
        <p className="mb-1">
          Reconstruction software <span>*</span>
        </p>
        <div className="step-select">
          <Select
            options={options2}
            classNamePrefix="step-select"
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Select reconstruction software"
            onChange={(e) =>
              setCreateProjectField((prevState) => ({
                ...prevState,
                softwareType: e.value,
              }))
            }
          />
        </div>
      </div>
    </div>
  );
}

export default StepOne;
