import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import './ProjectsBox.scss';
import { listProject } from '../../../api/ProjectApi';
import DashboardBox from '../../atoms/DashboardBox/DashboardBox';
import noproject from '../../../assets/images/noprojects.svg';
import ProjectPreview from '../../atoms/ProjectPreview/ProjectPreview';
// import preview1 from '../../../assets/images/preview1.png';
// import preview2 from '../../../assets/images/preview2.png';

function ProjectsBox({ modalHandler }) {
  const [projectList, setProjectList] = useState([]);
  console.log('Hello 3rd changes');
  useEffect(() => {
    listProject()
      .then((e) => setProjectList(e))
      .catch((error) => console.log(error.message));
  }, []);

  return (
    <div className="projects-box">
      <DashboardBox heading="All Projects">
        {projectList && projectList.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap">
            {projectList.map((data) => (
              <ProjectPreview
                key={data.id}
                id={data.id}
                title={data.project_name}
                time={new Date(data.updated_at).toDateString()}
                previewImg={data.image_url}
                modelUrl={data.project_file_url}
              />
            ))}
          </div>
        ) : (
          <div className="no-projects d-flex flex-column align-items-center justify-content-center text-center">
            <img src={noproject} alt="no-project" className="mb-4" />
            <p className="mb-3">
              No projects for now. Let us help you get started.
            </p>
            <Button onClick={modalHandler}>Create new project</Button>
          </div>
        )}
      </DashboardBox>
    </div>
  );
}

export default ProjectsBox;
