import React, { useState } from 'react';
import './DashboardLayout.scss';
import { Button, Dropdown } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import toggleIcon from '../../assets/images/toggler.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/link.svg';
import profile from '../../assets/images/profile.png';
import menuIcon from '../../assets/images/menu-icon.svg';
import notification from '../../assets/images/notification-icon.svg';
import closedToggle from '../../assets/images/closedtoggle.svg';

function DashboardLayout(props) {
  const windowWidth = window.innerWidth <= 1200;
  const [sidebar, setSidebar] = useState(!windowWidth);

  return (
    <div className="dashboard-layout d-flex">
      {sidebar ? (
        <aside className="sidebar">
          <div className="sidebar-logo d-flex align-items-center justify-content-between">
            <div className="sidebar-logo-img">
              <img src={logo} alt="logo" className="me-3" />
            </div>
            <Button
              className="toggle-button mb-1 px-0"
              onClick={() => setSidebar(!sidebar)}
            >
              <img src={toggleIcon} alt="toggler" />
            </Button>
          </div>
          <div className="sidebar-menu-header">
            <p>Menu</p>
          </div>
          <div className="menu d-flex flex-column">
            <NavLink to="/dashboard/projects" className="nav-menu-item">
              <div className="menu-item">
                <span className="active-dot me-3" />
                <LinkIcon className="me-3" />
                <p>Projects</p>
              </div>
            </NavLink>
            <NavLink to="/dashboard/project/id" className="nav-menu-item">
              <div className="menu-item">
                <span className="active-dot me-3" />
                <LinkIcon className="me-3" />
                <p>View project</p>
              </div>
            </NavLink>
          </div>
        </aside>
      ) : (
        <aside
          className={`sidebar-closed d-none ${
            !sidebar ? 'd-lg-block' : 'd-none'
          }`}
        >
          <div className="sidebar-logo d-flex align-items-center">
            <Button
              className="toggle-button"
              onClick={() => setSidebar(!sidebar)}
            >
              <img src={closedToggle} alt="toggler" />
            </Button>
          </div>
          <div className="sidebar-menu-header">
            <p>Menu</p>
          </div>
          <div className="menu d-flex flex-column">
            <NavLink to="/dashboard/projects" className="nav-menu-item">
              <div className="menu-item">
                <span className="active-dot me-3" />
                <LinkIcon className="me-3" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/project/id" className="nav-menu-item">
              <div className="menu-item">
                <span className="active-dot me-3" />
                <LinkIcon className="me-3" />
              </div>
            </NavLink>
          </div>
        </aside>
      )}

      <div className="content-section">
        <header
          className={`app-header ${
            !sidebar ? 'justify-content-between' : 'justify-content-end'
          }`}
        >
          {!sidebar ? (
            <>
              <div className="top-logo d-none d-sm-block">
                <div className="top-logo-img">
                  <img src={logo} alt="logo" className="me-md-1" />
                </div>
              </div>
              <Button
                className="toggle-button d-lg-none mb-1 px-0"
                onClick={() => setSidebar(!sidebar)}
              >
                <img src={toggleIcon} alt="toggler" />
              </Button>
            </>
          ) : (
            ''
          )}
          <div className="ms-auto">
            <div className="d-flex align-items-center">
              <div className="me-1 me-sm-5">
                <img src={notification} alt="notifications" />
              </div>
              <div className="user-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="user-header-logo me-2">
                    <img
                      src={profile}
                      alt="profile"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="user-header-content">
                    <p>Andy Warhol</p>
                    <span>andywarhol@mail.com</span>
                  </div>
                </div>
                <div className="user-header-menu">
                  <Dropdown>
                    <Dropdown.Toggle variant="transparent">
                      <img src={menuIcon} alt="menu-icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link to="/" className="dropdown-item">
                        Log Out
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="content-body">{props.children}</div>
      </div>
    </div>
  );
}

export default DashboardLayout;
