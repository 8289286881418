import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// import DraftsBox from '../../../organisms/DraftsBox/DraftsBox';
import ProjectsBox from '../../../organisms/ProjectsBox/ProjectsBox';
import ProjectHeader from '../../../organisms/ProjectHeader/ProjectHeader';
import CreateProject from '../../../atoms/CreateProject/CreateProject';

function DashProjects() {
  const [show, setShow] = useState(false);
  const modalHandler = () => setShow(!show);

  return (
    <div>
      <ProjectHeader modalHandler={modalHandler} />
      <div className="dashboard-content px-1 px-sm-3 py-5">
        <Container>
          <Row className="gy-5 gx-xxl-5">
            <Col xxl="12">
              <ProjectsBox modalHandler={modalHandler} />
            </Col>
            {/* <Col xxl="5">
              <DraftsBox />
            </Col> */}
          </Row>
        </Container>
      </div>
      <CreateProject show={show} modalHandler={modalHandler} />
    </div>
  );
}

export default DashProjects;
