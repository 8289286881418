import React, { useState } from 'react';
import './ViewProjects.scss';
import ViewHeader from '../../../organisms/ViewHeader/ViewHeader';
import View3d from '../../../organisms/View3d/View3d';

function ViewProjects() {
  const [showAnnotation, setShowAnnotation] = useState(false);
  const annotationHandler = () => {
    setShowAnnotation(!showAnnotation);
  };
  return (
    <>
      <ViewHeader showAnnotation={showAnnotation} />
      <View3d
        annotationHandler={annotationHandler}
        showAnnotation={showAnnotation}
      />
    </>
  );
}

export default ViewProjects;
