import React from 'react';
import './DashboardBox.scss';

function DashboardBox(props) {
  return (
    <div className="dasboard-box">
      <div className="box-headeing mb-3">
        <p>{props.heading}</p>
      </div>
      <div className="box-content">{props.children}</div>
    </div>
  );
}

export default DashboardBox;
