import React from 'react';
import { useGLTF } from '@react-three/drei';
// import table from './1v1.glb';

// const model =
//   'https://deltascan-bucket.s3.ap-south-1.amazonaws.com/projects/hamzaashes%40gmail.com/adsd/model.glb';

export default function Model({ modelUrl }) {
  const gltf = useGLTF(modelUrl);
  console.log(gltf)
  return <primitive object={gltf.scene} />;
}
